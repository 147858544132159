<template>
  <div class="app-contianer">
    <ul class="menu-box">
      <li>
        <span class="page-icon2 shop"></span>
        <div class="info flex-1 margin-lr2">
          <p><strong>商家入驻</strong></p>
          <p class="grey">上万家餐饮商家已入驻</p>
        </div>
        <div class="mainBut" @click="goPage('SHOP')">立即申请</div>
      </li>
      <li>
        <span class="page-icon2 city"></span>
        <div class="info flex-1 margin-lr2">
          <p><strong>城市代理</strong></p>
          <p class="grey">城市代理邀您共赢未来</p>
        </div>
        <div class="mainBut" @click="goPage('AGENT')">立即申请</div>
      </li>
      <li>
        <span class="page-icon2 software"></span>
        <div class="info flex-1 margin-lr2">
          <p><strong>软件服务</strong></p>
          <p class="grey">让我们一起共创美好</p>
        </div>
        <div class="mainBut" @click="goPage('SERVICE')">立即申请</div>
      </li>
    </ul>

  </div>
</template>

<script>
export default {
  name: 'cooperation',
  data() {
    return {

    }
  },
  created() { },
  methods: {
    // 页面跳转
    goPage(val) {
      this.$router.push({ path: '/coopSubmit', query: { type: val } })
    },
  }
}
</script>

<style lang='less' scoped>
.menu-box {
  background: #fff;
  padding: 0 4vw;
  li {
    display: flex;
    align-items: center;
    padding: 4vw 0;
  }
  li + li {
    border-top: 1px solid #efefef;
  }
  .page-icon2 {
    width: 38px;
    height: 38px;
  }
  .shop {
    background-position: -30px -206px;
  }
  .city {
    background-position: -89px -206px;
  }
  .software {
    background-position: -139px -206px;
  }
}
</style>